import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import queryString from 'query-string';
//import MetaTags from 'react-meta-tags';

import HeaderSub from './HeaderSub';

class ContactFormFiled extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        tel: '',
        email: '',
        mesaj: ''
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.ileten === "Z") {
            this.setState({
                konu: parsed.konu,
                ad: parsed.ad,
                soyad: parsed.soyad,
                tel: parsed.tel,
                email: parsed.email,
                mesaj: parsed.mesaj
            });
            this.props.invokeLogger({ id: "w", text: `ContactForm: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ad: ${parsed.ad}, soyad: ${parsed.soyad}, tel: ${parsed.tel}, email: ${parsed.email}, konu: ${parsed.konu}, mesaj: ${parsed.mesaj}` });
        } else {
            this.props.invokeLogger({ id: "w", text: "ContactForm: bilinmeyen bir client tarafından çağırıldı, data yok" });
        }
        window.scrollTo(0, 0);
    }

    render() {        
        return (
            <div>
                {/*
                <MetaTags>
                    <title>Contact Form Received | Thank You | Healthierge</title>
                    <meta name="description" content="Mammoplasty-Breast Augmentation-Breast Reduction-Breast Lift" />
                </MetaTags>
                */}
                <HeaderSub header='İetişim Formu Alındı' />
                <div className="Page">
                    <div className="PageText">
                        Doldurduğunuz iletişim formu, aşağıda gördüğünüz bilgilerle elimize ulaştı. En kısa zamanda derZane müşteri hizmetleri sizinle iletişime geçecek. Bize yazdığınız için teşekkür ederiz. 
                    </div>
                    <div className="PageTable">
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                Ad
                            </div>
                            <div className="PageTableCell">
                                {this.state.ad}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                Soyad
                            </div>
                            <div className="PageTableCell">
                                {this.state.soyad}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                Telefon
                            </div>
                            <div className="PageTableCell">
                                {this.state.tel}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                E-Posta
                            </div>
                            <div className="PageTableCell">
                                {this.state.email}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                Konu
                            </div>
                            <div className="PageTableCell">
                                {this.state.konu}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                Mesaj
                            </div>
                            <div className="PageTableCell">
                                {this.state.mesaj}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(ContactFormFiled);