const ACCOUNT = {
    FETCH: 'ACCOUNT_FETCH',
    FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
    FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
    FETCH_LOGOUT_SUCCESS: 'ACCOUNT_FETCH_LOGOUT_SUCCESS',
    FETCH_AUTHENTICATED_SUCCESS: 'ACCOUNT_FETCH_AUTHENTICATED_SUCCESS',
    UPDATE: 'ACCOUNT_UPDATE',
    UPDATE_ERROR: 'ACCOUNT_UPDATE_ERROR',
    UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
    UPDATEABONE: 'ACCOUNT_UPDATEABONE',
    UPDATEABONE_ERROR: 'ACCOUNT_UPDATEABONE_ERROR',
    UPDATEABONE_SUCCESS: 'ACCOUNT_UPDATEABONE_SUCCESS',
    ACTIVATE: 'ACCOUNT_ACTIVATE',
    ACTIVATE_ERROR: 'ACCOUNT_ACTIVATE_ERROR',
    ACTIVATE_SUCCESS: 'ACCOUNT_ACTIVATE_SUCCESS',
    UPDATEDISCLAIMER: 'ACCOUNT_UPDATEDISCLAIMER',
    UPDATEDISCLAIMER_ERROR: 'ACCOUNT_UPDATEDISCLAIMER_ERROR',
    UPDATEDISCLAIMER_SUCCESS: 'ACCOUNT_UPDATEDISCLAIMER_SUCCESS',
    FORGOTTENPASSWORD: 'ACCOUNT_FORGOTTENPASSWORD',
    FORGOTTENPASSWORD_ERROR: 'ACCOUNT_FORGOTTENPASSWORD_ERROR',
    FORGOTTENPASSWORD_SUCCESS: 'ACCOUNT_FORGOTTENPASSWORD_SUCCESS',
    SENDACTIVATIONMAIL: 'ACCOUNT_SENDACTIVATIONMAIL',
    SENDACTIVATIONMAIL_ERROR: 'ACCOUNT_SENDACTIVATIONMAIL_ERROR',
    SENDACTIVATIONMAIL_SUCCESS: 'ACCOUNT_SENDACTIVATIONMAIL_SUCCESS',
    INVOKELOGGER: 'ACCOUNT_INVOKELOGGER',
    INVOKELOGGER_ERROR: 'ACCOUNT_INVOKELOGGER_ERROR',
    INVOKELOGGER_SUCCESS: 'ACCOUNT_INVOKELOGGER_SUCCESS',
    WINDOWCHECK: 'ACCOUNT_WINDOWCHECK',
    WINDOWCHECK_ERROR: 'ACCOUNT_WINDOWCHECK_ERROR',
    WINDOWCHECK_SUCCESS: 'ACCOUNT_WINDOWCHECK_SUCCESS'
};

const AYARLAR = {
    FETCH: 'AYARLAR_FETCH',
    FETCH_ERROR: 'AYARLAR_FETCH_ERROR',
    FETCH_SUCCESS: 'AYARLAR_FETCH_SUCCESS',
    UPDATE: 'AYARLAR_UPDATE',
    UPDATE_ERROR: 'AYARLAR_UPDATE_ERROR',
    UPDATE_SUCCESS: 'AYARLAR_UPDATE_SUCCESS'
}

const DERS = {
    FETCH: 'DERS_FETCH',
    FETCH_ERROR: 'DERS_FETCH_ERROR',
    FETCH_SUCCESS: 'DERS_FETCH_SUCCESS'
};

const KONU = {
    FETCH: 'KONU_FETCH',
    FETCH_ERROR: 'KONU_FETCH_ERROR',
    FETCH_SUCCESS: 'KONU_FETCH_SUCCESS'
};

const ALTKONU = {
    FETCH: 'ALTKONU_FETCH',
    FETCH_ERROR: 'ALTKONU_FETCH_ERROR',
    FETCH_SUCCESS: 'ALTKONU_FETCH_SUCCESS'
};

const DERSSORU = {
    FETCH: 'DERSSORU_FETCH',
    FETCH_ERROR: 'DERSSORU_FETCH_ERROR',
    FETCH_SUCCESS: 'DERSSORU_FETCH_SUCCESS',
    FETCH_DETAILS: 'DERSSORU_FETCH_DETAILS',
    INSERT: 'DERSSORU_INSERT',
    INSERT_ERROR: 'DERSSORU_INSERT_ERROR',
    INSERT_SUCCESS: 'DERSSORU_INSERT_SUCCESS',
    UPDATE: 'DERSSORU_UPDATE',
    UPDATE_ERROR: 'DERSSORU_UPDATE_ERROR',
    UPDATE_SUCCESS: 'DERSSORU_UPDATE_SUCCESS',
    DELETE: 'DERSSORU_DELETE',
    DELETE_ERROR: 'DERSSORU_DELETE_ERROR',
    DELETE_SUCCESS: 'DERSSORU_DELETE_SUCCESS',
    ZAMAN: 'DERSSORU_ZAMAN'
}

const CEVAP = {
    INSERT: 'CEVAP_INSERT',
    INSERT_ERROR: 'CEVAP_INSERT_ERROR',
    INSERT_SUCCESS: 'CEVAP_INSERT_SUCCESS'
};

const STATS = {
    FETCH: 'STATS_FETCH',
    FETCH_ERROR: 'STATS_FETCH_ERROR',
    FETCH_SUCCESS: 'STATS_FETCH_SUCCESS'
};

export { ACCOUNT, AYARLAR, DERS, KONU, ALTKONU, DERSSORU, CEVAP, STATS };