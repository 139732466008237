import { BACKEND } from '../secrets/config';
import { ACCOUNT } from './types';

const fetchFromAccount = ({ endpoint, options, SUCCESS_TYPE }) => dispatch => {
    dispatch({ type: ACCOUNT.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Account/${endpoint}`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error' ) {
                dispatch({ type: ACCOUNT.FETCH_ERROR, message: json.message });
            } else {
                dispatch({ type: SUCCESS_TYPE, ...json });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.FETCH_ERROR, message: error.message
        }));
}

export const signup = ({ username, password }) => fetchFromAccount({
    endpoint: 'Olustur',
    options: {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_SUCCESS
});

export const login = ({ username, password }) => fetchFromAccount({
    endpoint: 'Giris',
    options: {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_SUCCESS
});

export const logout = (id) => fetchFromAccount({
    endpoint: 'Cikis',
    options: {
        method: 'POST',
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_LOGOUT_SUCCESS
});

export const fetchAuthenticated = ({
    id,
    serialNumber,
    dateOfFirstVisit,
    accountId,
    language,
    platform,
    vendor,
    appName,
    userAgent,
    product,
    productSub,
    cookie
}) => fetchFromAccount({
    endpoint: 'Oturum',
    options: {
        method: 'POST',
        body: JSON.stringify({
            id,
            serialNumber,
            dateOfFirstVisit,
            accountId,
            language,
            platform,
            vendor,
            appName,
            userAgent,
            product,
            productSub,
            cookie
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    },
    SUCCESS_TYPE: ACCOUNT.FETCH_AUTHENTICATED_SUCCESS
});

export const updateAccount = ({
    id,
    ad,
    soyad,
    cinsiyet,
    okulId,
    sinifId,
    accountStatus,
    haberler,
    password
}) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/Account/Degistir`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                ad,
                soyad,
                cinsiyet,
                okulId,
                sinifId,
                accountStatus,
                haberler,
                password
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATE_SUCCESS,
                    account: json.account
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATE_ERROR,
            message: error.message
        }));
}

export const updateAbone = ({
    id,
    ad,
    soyad,
    telefon,
    adres,
    postaKodu,
    ilce,
    sehir
}) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATEABONE });

    return fetch(`${BACKEND.ADDRESS}/Account/Abone`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                ad,
                soyad,
                telefon,
                adres,
                postaKodu,
                ilce,
                sehir
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATEABONE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATEABONE_SUCCESS,
                    account: json.account
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATEABONE_ERROR,
            message: error.message
        }));
}

export const updateDisclaimer = ({ id, sozlesme }) => dispatch => {
    dispatch({ type: ACCOUNT.UPDATEDISCLAIMER });

    return fetch(`${BACKEND.ADDRESS}/Account/Sozlesme`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                sozlesme
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.UPDATEDISCLAIMER_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.UPDATEDISCLAIMER_SUCCESS,
                    sozlesme: json.sozlesme
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.UPDATEDISCLAIMER_ERROR,
            message: error.message
        }));
}

export const forgottenPassword = ({ username }) => dispatch => {
    dispatch({ type: ACCOUNT.FORGOTTENPASSWORD });

    return fetch(`${BACKEND.ADDRESS}/Account/Forgotten`,
        {
            method: 'POST',
            body: JSON.stringify({
                username
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.FORGOTTENPASSWORD_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.FORGOTTENPASSWORD_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.FORGOTTENPASSWORD_ERROR,
            message: error.message
        }));
}

export const activateAccount = (parsed) => dispatch => {
    dispatch({ type: ACCOUNT.ACTIVATE });

    const { id, activationToken } = parsed;
    return fetch(`${BACKEND.ADDRESS}/Account/Activate`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                activationToken
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.ACTIVATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.ACTIVATE_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.ACTIVATE_ERROR,
            message: error.message
        }));
}

export const sendActivationMail = ({ id, email }) => dispatch => {
    dispatch({ type: ACCOUNT.SENDACTIVATIONMAIL });

    return fetch(`${BACKEND.ADDRESS}/Account/SendActivationMail`,
        {
            method: 'POST',
            body: JSON.stringify({ id, email }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.SENDACTIVATIONMAIL_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.SENDACTIVATIONMAIL_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.SENDACTIVATIONMAIL_ERROR,
            message: error.message
        }));
}

export const invokeLogger = ({ id, text }) => dispatch => {
    dispatch({ type: ACCOUNT.INVOKELOGGER });

    return fetch(`${BACKEND.ADDRESS}/Account/Logger`,
        {
            method: 'POST',
            body: JSON.stringify({ id, text }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.INVOKELOGGER_ERROR,
            message: error.message
        }));
}

export const windowCheck = ({
    id,
    serialNumber,
    dateOfFirstVisit,
    accountId,
    language,
    platform,
    vendor,
    appName,
    userAgent,
    product,
    productSub,
    cookie
}) => dispatch => {
    dispatch({ type: ACCOUNT.WINDOWCHECK });

    return fetch(`${BACKEND.ADDRESS}/Account/WindowCheck`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                serialNumber,
                dateOfFirstVisit,
                accountId,
                language,
                platform,
                vendor,
                appName,
                userAgent,
                product,
                productSub,
                cookie
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.WINDOWCHECK_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.WINDOWCHECK_SUCCESS,
                    message: json.message,
                    visitor: json.visitor
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.WINDOWCHECK_ERROR,
            message: error.message
        }));
}