import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import queryString from 'query-string';
import { signup,
         login,
         activateAccount,
         forgottenPassword } from './actions/account';
import fetchStates from './reducers/fetchStates';
import HeaderSub from './HeaderSub';
import spinnerImg from './assets/derzane-arma-mavi-200.png';

class AuthForm extends Component {
    state = {
        username: '',
        password: '',
        buttonClicked: false,
        usernameError: ''
    };

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.activate === '1') {
            this.setState({ buttonClicked: true });
            this.props.activateAccount(parsed);
        }

        window.scrollTo(0, 0);
    }

    updateUsername = event => {
        this.setState({
            username: event.target.value.toLowerCase(),
            usernameError: ''
        });
    }

    updatePassword = event => {
        this.setState({
            password: event.target.value,
            usernameError: ''
        });
    }

    keyPress = (e) => {
        if (e.key === 'Enter')
            this.login();
    }

    signup = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Lütfen ilgili alanlara e-posta ve şifrenizi giriniz ve 'Yeni Üye' tuşuna basarak kaydınızı oluşturunuz"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "Lütfen şifrenizi giriniz"});
                    return;
                }
    
                this.setState({ buttonClicked: true });
                this.props.signup({ username, password });
            }
        }
    }

    login = () => {
        const { username, password } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Lütfen ilgili alanlara e-posta ve şifrenizi giriniz ve 'Mevcut Üye' tuşuna basarak sisteme giriş yapınız"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {
                if (password === '') {
                    this.setState({ usernameError: "Lütfen şifrenizi giriniz"});
                    return;
                }
                this.setState({ buttonClicked: true });
                this.props.login({ username, password });
            }
        }
    }

    forgottenPassword = () => {
        const { username } = this.state;

        this.setState({ buttonClicked: false });
        if (username === '') {
            this.setState({ usernameError: "Lütfen sistemimize e-posta adresinizi girdikten sonra 'Şifremi Unuttum' tuşuna basınız"});
            return;
        } else {
            if (this.ePostaKontrol(username)) {    
                this.setState({ buttonClicked: true });
                this.props.forgottenPassword({ username });
            }
        }
    }

    Error(plc) {
        if (this.state.buttonClicked) {
            if (this.props.account.status === fetchStates.error ||
                this.props.account.status === fetchStates.success) {
                return <div className={`Warning ${plc}`}>{this.props.account.message}</div>
            } 
        }
        
        if (this.state.usernameError !== '')
            return <div className={`Warning ${plc}`}>{this.state.usernameError}</div>
        
        return null
    }

    ePostaKontrol(ePosta) {
        let pos = ePosta.indexOf("@");

        if (pos === -1) {
            this.setState({ usernameError: "Lütfen '@' karakterini de içeren e-posta adresinizi giriniz"});
            return false;
        }

        let str = ePosta.substring(pos+1);

        pos = str.indexOf(".");
        if (pos < 0) {
            this.setState({ usernameError: "Lütfen '.' karakterini de içeren e-posta adresinizi giriniz"});
            return false;
        }

        if (pos === 0 || pos === str.length-1) {
            this.setState({ usernameError: "Lütfen sistemimizde kayıtlı olan e-posta adresinizi giriniz"});
            return false;
        }

        return true;
    }

    render() {
        return (
            <div>
                <HeaderSub header="Sisteme Giriş" />  
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning w_top">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Sunucuya bağlanıyorum
                    </div> : this.Error('w_top')
                }
                <div className="Page">
                    <div className="PageText">
                        İlgili alanları doldurduktan sonra,
                        eğer henüz üyemiz değilseniz lütfen 'Yeni Üye' tuşuna basarak kayıt oluşturunuz veya
                        daha önce kayıt olduysanız 'Mevcut Üye' tuşuna basarak sisteme giriş yapınız.
                    </div>
                    <div className="PageInput">
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>E-Posta</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="EMail"
                                value={this.state.username}
                                onChange={this.updateUsername}
                                onKeyPress={this.keyPress}
                                placeholder='Lütfen e-posta adresinizi giriniz'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ "width" : '90px' }}>Şifre</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Şifre"
                                value={this.state.password}
                                onChange={this.updatePassword}
                                onKeyPress={this.keyPress}
                                placeholder='Lütfen şifrenizi giriniz'
                                type="password"
                            />
                        </InputGroup>
                    </div>
                    {
                        this.props.account.status !== fetchStates.fetching ?
                        <div className="PageInput">
                            <Button variant="success" onClick={this.login}>Mevcut Üye</Button>
                            <span>&nbsp;</span>
                            <Button variant="warning" onClick={this.signup}>Yeni Üye</Button>
                            <span>&nbsp;</span>
                            <Button variant="danger" onClick={this.forgottenPassword}>Şifremi Unuttum</Button>
                        </div> : null
                    }
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                    <div className="Warning w_bottom">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Sunucuya bağlanıyorum
                    </div> : this.Error('w_bottom')
                }
            </div>
        );
    }
}

export default connect(
    ({ account }) => ({ account }),
    { signup, login, activateAccount, forgottenPassword }
)(AuthForm);