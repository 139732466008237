import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class DenemeAsamasi extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "DenemeAsamasi: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Deneme Sürecindeyiz'/>
                <div className="Page">      
                    <div className="PageText">
                    Sitemize hoşgeldiniz.
                    </div>
                    <div className="PageText">
                    Bu site LGS sınavına hazırlık için tasarlanmıştır.
                    Soru bankasında LGS kapsamında olan Matematik, Fen Bilgisi, Türkçe, İnkılap 
                    Tarihi, Din Kültürü ve Ahlak Bilgisi dersleri ile ilgili sorular bulunmaktadır.
                    Sistemdeki soru sayısı şu anda sorular yüklendiği için günden güne artmaktadır. 
                    Yükleme tamamlanana kadar soru bankası deneme aşamasında olarak servis verecek ve 
                    soru çözme kullanımına kapalı olacaktır.
                    </div>
                    <div className="PageText">
                    Site kullanıma açıldığında kayıt sırasında belirttiğiniz adresinizden size bilgi mesajı 
                    gönderilecektir. Lütfen henüz belirtmediyseniz Profilim alanındaki kişisel bilgilerinizi 
                    doldurunuz ve verilerinizin güncel kalmasını sağlayınız. Çok yakında sizi soru çözebilmeniz
                    için bilgilendireceğiz.
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(DenemeAsamasi);