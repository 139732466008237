import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Tail extends Component {
    render () {
        return (
            <div className="Tail">
                <div className="TailReserved">
                © 2021-{(new Date().getFullYear())} <span className="TailReservedLight">derZane.com</span> Tüm Hakları Saklıdır
                </div>
                {
                this.props.account.loggedIn ? 
                <div className="TailMenu">
                    <span>
                        <Link className="TailMenuItem" to="/iletisim">İletişim</Link>
                        <Link className="TailMenuItem" to="/gizlilik-politikasi">Gizlilik Politikası</Link>
                    </span>
                </div> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(Tail);