import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { updateAccount, updateAbone, invokeLogger } from './actions/account';

import fetchStates from './reducers/fetchStates';
import HeaderSub from './HeaderSub';
import Spinner from './Spinner';

class AboneOl extends Component {
    state = {
        ad: this.props.account.ad,
        soyad: this.props.account.soyad,
        telefon: this.props.account.telefon,
        adres: this.props.account.adres,
        postaKodu: this.props.account.postaKodu,
        ilce: this.props.account.ilce,
        sehir: this.props.account.sehir,
        error: ''
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "AboneOl: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    checkValidity() {
        let {
            ad,
            soyad,
            telefon,
            adres,
            postaKodu,
            ilce,
            sehir
        } = this.state;

        let error = '';

        if (sehir === '') {
            error = 'Lütfen şehrinizi güncelleyiniz';
        }
        if (ilce === '') {
            error = 'Lütfen ilçenizi güncelleyiniz';
        }
        if (postaKodu === '') {
            error = 'Lütfen posta kodunuzu güncelleyiniz';
        }
        if (adres === '') {
            error = 'Lütfen adresinizi güncelleyiniz';
        }
        if (telefon === '') {
            error = 'Lütfen size ulaşabileceğimiz telefon numarasını güncelleyiniz';
        }
        if (soyad === '') {
            error = 'Lütfen soyadınızı güncelleyiniz';
        }
        if (ad === '') {
            error = 'Lütfen adınızı güncelleyiniz';
        }

        this.setState({ error });
        return error === '';
    }

    Error(plc) {
        return (
            <div>
            {
                this.state.error !== '' ?
                <div className={`Warning ${plc}`}>
                    {this.state.error}
                </div>
                : this.props.account.accountStatus !== "FM" ?
                <div className={`Warning ${plc}`}>Lütfen abonelik bilgilerinizi güncelleyiniz</div>
                : null
            }
            </div>
        )
    }

    updateMyAccount = () => {
        let {
            ad,
            soyad,
            telefon,
            adres,
            postaKodu,
            ilce,
            sehir
        } = this.state;

        let {
            id
        } = this.props.account;

        if (this.checkValidity()) {
            this.props.updateAbone({
                id,
                ad,
                soyad,
                telefon,
                adres,
                postaKodu,
                ilce,
                sehir
            });
        }
    }

    render() {
        return (
            <div>
                <HeaderSub header="Abonelik İşlemleri" />
                {
                    this.props.account.status === fetchStates.fetching ?
                    <Spinner place='w_top' text='Bilgiler güncelleniyor' /> : this.Error('w_top')
                }
                <div className="PageCenter">
                    <div className="PageInput">
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Ad</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="ad"
                                value={this.state.ad}
                                onChange={this.handleChange}
                                placeholder='Lütfen adınızı giriniz'
                                name='ad'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Soyad</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.soyad}
                                onChange={this.handleChange}
                                placeholder='Lütfen soyadınızı giriniz'
                                name='soyad'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Telefon</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.telefon}
                                onChange={this.handleChange}
                                placeholder='Lütfen size ulaşabileceğimiz telefon numarası giriniz'
                                name='telefon'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Adres</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.adres}
                                onChange={this.handleChange}
                                placeholder='Lütfen adresinizi giriniz'
                                name='adres'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Posta Kodu</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.postaKodu}
                                onChange={this.handleChange}
                                placeholder='Lütfen posta kodunuzu giriniz'
                                name='postaKodu'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>İlçe</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.ilce}
                                onChange={this.handleChange}
                                placeholder='Lütfen oturduğunuz ilçeyi giriniz'
                                name='ilce'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '120px' }}>Şehir</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.sehir}
                                onChange={this.handleChange}
                                placeholder='Lütfen oturduğunuz şehri giriniz'
                                name='sehir'
                            />
                        </InputGroup>
                        <div className='PageTextCenter'>
                        <Button variant="info" onClick={this.updateMyAccount}>Fatura bilgilerimi kaydet</Button>
                        </div>
                    </div>
                </div>
                {
                    this.props.account.status === fetchStates.fetching ?
                    <Spinner place='w_bottom' text='Bilgiler güncelleniyor' /> : this.Error('w_bottom')
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateAccount, updateAbone, invokeLogger }
)(AboneOl);