import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

class SoruBar extends Component {
    goIndex = (event) => {
        this.props.goIndex(event.target.id*1); // *1 added for string to number conversion
    }

    goPrev = () => {
        if (this.props.soruIndex > 0) {
            this.props.goIndex(this.props.soruIndex-1);
        }
    }

    goNext = () => {
        if (this.props.soruIndex < this.props.dersSoru.sorular.length - 1) {
            this.props.goIndex(this.props.soruIndex+1);
        }
    }

    render() {
        let sorular = this.props.dersSoru.sorular;
        let soruIndex = this.props.soruIndex;

        let baslangicIndex = 0; /* soruIndex - 3;*/
        let bitisIndex = sorular.length -1; /* soruIndex + 3; */

        if (baslangicIndex < 0) {
            bitisIndex += -baslangicIndex;
            baslangicIndex = 0;
        }

        if (bitisIndex > sorular.length - 1) {
            baslangicIndex -= bitisIndex - sorular.length + 1;
            bitisIndex = sorular.length - 1;
        }

        if (baslangicIndex < 0)
            baslangicIndex = 0;

        let cNString = '';

        return (
            <div className='SoruBar'>
                <Button variant="info" size="lg" onClick={this.goPrev} disabled={soruIndex === 0}>&lt;</Button>
                {
                    sorular.map((soru, index) => {
                        if (baslangicIndex <= index && index <= bitisIndex) {
                            cNString = (soruIndex === index ? 'SoruGoruntulenen ' : 'SoruDiger ');
                            cNString += (soru.verilenCevap ? 
                                this.props.dogruCevapGoster === 'A' &&
                                soru.verilenCevap === soru.dogruCevap ?
                                "SoruDogruCevapli" : "SoruCevapli" : "SoruCevapsiz");
                            return (    
                                <span
                                    className={cNString}
                                    key={index}
                                    id={index}
                                    onClick={this.goIndex}
                                >
                                    {index+1}
                                </span>
                            );
                        } else
                            return null;
                    })
                }
                {/*<span>{bitisIndex < sorular.length - 1 ? '...' : ''}</span>*/}
                <Button variant="info" size="lg" onClick={this.goNext} disabled={soruIndex === sorular.length - 1}>&gt;</Button>
            </div>
        );
    }
}

export default connect(
    ({ dersSoru }) => ({ dersSoru }),
    null
)(SoruBar);