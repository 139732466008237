import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import CevapAnahtariSatir from './CevapAnahtariSatir';
import SoruGoster from './SoruGoster';

class CevapAnahtari extends Component {
    state = {
        showSoru: false,
        soru: '',
        index: 0
    }

    soruGoster = (soru, index) => {
        this.setState({ soru, index, showSoru: !this.state.showSoru });
    }

    kapat = () => {
        if (this.props.kapat)
            this.props.kapat();
    }

    zamanString = (zaman) => {
        let str = '';
        let saat = 0;
        let dakika = 0;
//        let mod = 60;
        
        if (zaman > 60) {
            dakika = Math.floor(zaman/60);
            if (dakika > 60) {
                saat = Math.floor(dakika/60);
                str += `${saat} saat `;
                dakika -= saat*60;
//                mod = 3600;
            }
            str += `${dakika} dak `;
        }
        str += `${Math.floor((zaman - saat*3600 - dakika * 60)*1000)/1000} sn`;
        //str += `${zaman % mod} san`;

        return (str);
    }

    render() {
        const sorular = this.props.dersSoru.sorular;
        let dogruSayi = 0;
        let yanlisSayi = 0;
        let bosSayi = 0;

        sorular.forEach(soru=>{
            if (soru.verilenCevap == null) {
                bosSayi += 1;
            } else {
                if (soru.dogruCevap === soru.verilenCevap) {
                    dogruSayi += 1;
                } else {
                    yanlisSayi += 1;
                }
            }
        });

        let netPuan = dogruSayi - yanlisSayi / 4;
        if (netPuan < 0) netPuan = 0;

        let zaman = this.props.dersSoru.zaman;
        let soruSayisi = Math.floor(this.props.dersSoru.soruSayisi);
        let ortalamaZaman = (Math.round(zaman/soruSayisi * 100)/100).toFixed(2);
        let kategoriAdi = "";
        switch(this.props.kategoriId) {
            case 'O': kategoriAdi = 'Öğren'; break;
            case 'U': kategoriAdi = 'Uygula'; break;
            case 'B':
            default: kategoriAdi = 'Başar'; break;
        }

        return (
            <div>
                {
                    this.state.showSoru ?
                    <SoruGoster
                        soru={this.state.soru}
                        soruNo={this.state.index}
                        randomSec={this.props.randomSec}
                        geriDon={() => this.soruGoster('')}
                    /> :
                    <div className='PageCenter'>
                        <div className='PageSection'>
                            Ünite: {this.props.konuAdi} <br />
                            Aşama: {kategoriAdi}
                        </div>
                        <div className='CevapAnahtari'>
                            <div className='CevapAnahtariBaslik'>
                                <div className='CevapAnahtariHucre'>Soru</div>
                                <div className='CevapAnahtariHucre'>Kayıt</div>
                                <div className='CevapAnahtariHucre'>Cevap</div>
                                <div className='CevapAnahtariHucre'>Doğru Şık</div>
                                <div className='CevapAnahtariHucre'>Sonuç</div>
                                <div className='CevapAnahtariHucre'>Cevap Süresi</div>
                                <div className='CevapAnahtariHucre'>Konu</div>
                            </div>
                            {
                                sorular.map((soru, index) => {
                                    return (
                                        <CevapAnahtariSatir
                                            key={index+1}
                                            soruNo={index+1}
                                            soru={soru}
                                            randomSec={this.props.randomSec}
                                            zamanString={this.zamanString}
                                            soruGoster={() => this.soruGoster(soru, index+1)}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className='PageText'>
                            <div>
                                Net: {netPuan} puan (
                                <span className='SoruDogruCevapli'>{dogruSayi} doğru</span>
                                , <span className='SoruCevapli'>{yanlisSayi} yanlış</span>
                                , {bosSayi} boş)
                            </div>
                            <div>
                            Toplam {this.zamanString(zaman)}, ortalama {this.zamanString(ortalamaZaman)}/soru
                            </div>
                        </div>
                        <div className='PageTextCenter'>
                            <Button variant='info' onClick={this.kapat}>Kapat</Button>
                        </div>
                        {/*
                        <div className='PageTextCenter'>
                            <Button variant='info' onClick={this.props.birTestDaha}>Bir Test Daha</Button>
                        </div>
                        */}
                    </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ account, dersSoru, altKonu }) => ({ account, dersSoru, altKonu }),
    null
)(CevapAnahtari);