import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { updateAccount,
         invokeLogger,
         sendActivationMail } from './actions/account';
//import STATIC_OKULLAR from './data/okullar.json';
import STATIC_SINIFLAR from './data/siniflar.json';

import fetchStates from './reducers/fetchStates';
import HeaderSub from './HeaderSub';
import spinnerImg from './assets/derzane-arma-mavi-200.png';
//import { borderRightStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';

class Profilim extends Component {
    state = {
        ad: this.props.account.ad,
        soyad: this.props.account.soyad,
        cinsiyet: this.props.account.cinsiyet,
        okulId: this.props.account.okulId,
        sinifId: this.props.account.sinifId,
        haberler: this.props.account.haberler,
        password: '',
        passwordChange: false,
        error: ''
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "Profilim: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    togglePasswordChange = (event) => {
        this.setState({ passwordChange: !this.state.passwordChange });
    }

    toggleNewsletter = (event) => {
        this.setState({ haberler: !this.state.haberler });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    checkValidity() {
        let {
            ad,
            soyad,
            cinsiyet,
            okulId,
            sinifId,
            password
        } = this.state;

        let error = '';

        if (this.state.passwordChange && password === '') {
            error = 'Lütfen yeni şifrenizi belirtiniz';
        }
        if (sinifId === 'XX') {
            error = 'Lütfen sınıfınızı güncelleyiniz';
        }
        if (okulId === '') {
            error = 'Lütfen okulunuzu güncelleyiniz';
        }
        if (cinsiyet === '') {
            error = 'Lütfen cinsiyetinizi güncelleyiniz';
        }
        if (soyad === '') {
            error = 'Lütfen soyadınızı güncelleyiniz';
        }
        if (ad === '') {
            error = 'Lütfen adınızı güncelleyiniz';
        }

        this.setState({ error });
        return error === '';
    }

    Error(plc) {
        return (
            <div>
            {
                this.state.error !== '' ?
                <div className={`Warning ${plc}`}>
                    {this.state.error}
                </div>
                : this.props.account.accountStatus === "I1" ?
                <div className={`Warning ${plc}`}>Lütfen profil bilgilerinizi güncelleyiniz</div>
                : null
            }
            </div>
        )
    }

    sendActivationMail = () => {
        let {
            id,
            email
        } = this.props.account;

        this.props.sendActivationMail({
            id,
            email
        });
    }

    updateMyAccount = () => {
        let {
            ad,
            soyad,
            cinsiyet,
            okulId,
            sinifId,
            haberler,
            password
        } = this.state;

        let {
            id,
            sozlesme,
            accountStatus
        } = this.props.account;

        if (!this.state.passwordChange) {
            password = '';
        }

        if (this.checkValidity()) {
            this.props.updateAccount({
                id,
                ad,
                soyad,
                cinsiyet,
                okulId,
                sinifId,
                haberler,
                sozlesme,
                accountStatus,
                password
            });
        }
    }

    render() {
        let { startDate } = this.props.account;

        let kayitTarihi = 'Hesabınız ' + startDate.substring(6, 8) + '/' 
                        + startDate.substring(4, 6) + '/' + 
                        startDate.substring(0,4) + 
                        ' tarihinden beri aktiftir';

        return (
            <div>
                <HeaderSub header="Profilim" />
                {
                this.props.account.status === fetchStates.fetching ?
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Bilgilerinizi güncelliyorum
                </div> : this.Error('w_top')
                }
                <div className="PageCenter">
                    <div className="PageInput">
                        <div className="PageInputSmall">
                            {kayitTarihi}
                        </div>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Ad</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="ad"
                                value={this.state.ad}
                                onChange={this.handleChange}
                                placeholder='Lütfen adınızı giriniz'
                                name='ad'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Soyad</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.soyad}
                                onChange={this.handleChange}
                                placeholder='Lütfen soyadınızı giriniz'
                                name='soyad'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Cinsiyet</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="select"
                                value={this.state.cinsiyet}
                                onChange={this.handleChange}
                                placeholder='cinsiyet'
                                name='cinsiyet'
                            >
                                <option key='' value=''>Cinsiyetinizi belirtiniz</option>
                                <option key='E' value='E'>Erkek</option>
                                <option key='K' value='K'>Kız</option>
                            </FormControl>  
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Okul</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                value={this.state.okulId}
                                onChange={this.handleChange}
                                placeholder='Lütfen okulunuzu giriniz'
                                name='okulId'
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Sınıf</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="select"
                                value={this.state.sinifId}
                                onChange={this.handleChange}
                                placeholder='sinifId'
                                name='sinifId'
                            >
                                <option key='XX' value='XX'>Sınıf Seçiniz</option>
                                {
                                    STATIC_SINIFLAR.map((item,i) => (
                                        <option key={item.sinifId} value={item.sinifId}>
                                            {item.sinifAdi}
                                        </option>
                                    ))
                                }
                            </FormControl>  
                        </InputGroup>
                        <div className="custom-control custom-checkbox PageText">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="haberler"
                                onChange={this.toggleNewsletter}
                                checked={this.state.haberler}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="haberler">
                                Bizden haber almak için işaretleyiniz
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox PageText">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="passwordChange"
                                onChange={this.togglePasswordChange}
                                checked={this.state.passwordChange}
                            />
                            <label
                                className="custom-control-label"
                                htmlFor="passwordChange">
                                Şifrenizi değiştirmek için işaretleyiniz
                            </label>
                        </div>
                        {
                        this.state.passwordChange ?
                        <div>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text style={{ width : '170px' }}>Yeni Şifre</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                placeholder='Lütfen yeni şifrenizi giriniz'
                                name='password'
                            />
                        </InputGroup>
                        <br />
                        </div>
                        : null
                        }
                        {
                            this.props.account.accountStatus === 'I0' ?
                            <div className='PageTextCenter'>
                                <Button variant="info" onClick={this.sendActivationMail}>Yeniden e-posta doğrulama mesajı gönder</Button>
                            </div> : null
                        }
                        <div className='PageTextCenter'>
                            <Button variant="info" onClick={this.updateMyAccount}>Profil bilgilerimi kaydet</Button>
                        </div>                        
                    </div>

                </div>
                {
                this.props.account.status === fetchStates.fetching ?
                <div className="Warning w_bottom">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Bilgilerinizi güncelliyorum
                </div> : this.Error('w_bottom')
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { updateAccount, invokeLogger, sendActivationMail }
)(Profilim);