import React, { Component } from 'react';
import { connect } from 'react-redux';

class CoverItem extends Component {
    state = {
        defaultBorderColor: 'BorderDefaultColor',
    }

    handleClick = () => {
            if (this.props.callPage)
                this.props.callPage(this.props.redirect);
    }

    handleMouseEnter = () => {
        this.setState({ defaultBorderColor: 'BorderHighlightColor' });
    }

    handleMouseLeave = () => {
        this.setState({ defaultBorderColor: 'BorderDefaultColor' });
    }

    render() {
        return (
            <div className="Cover">
                {this.props.active ?
                <div
                    className={"CoverItem " + this.state.defaultBorderColor}
                    onClick={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                > 
                    <div className="CoverItemImage">
                        {
                            this.props.image !== "" ?
                            <img src={this.props.image} alt="derZane.com" /> : null
                        }
                    </div>
                    <div className="CoverItemHeader">
                        {this.props.header}
                    </div>
                    {
                        this.props.soruSayisi > 0 ?
                        <div className="CoverItemText">
                            {this.props.soruSayisi} soru
                        </div>
                        :
                        <div className="CoverItemText">
                            &nbsp;
                        </div>

                    }
                </div>
                : 
                <div
                    className={"CoverItem"}
                > 
                    <div className="CoverItemImage">
                        {
                            this.props.image !== "" ?
                            <img src={this.props.image} alt="derZane.com" /> : null
                        }
                    </div>
                    <div className="CoverItemHeader">
                        {this.props.header}
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
)(CoverItem);