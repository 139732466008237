import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import 'suneditor/dist/css/suneditor.min.css';
import 'katex/dist/katex.min.css';

const soruMetinStyle = {
    fontFamily: 'Avenir',
    fontSize: '20px',
    color: '#106c84',
    padding: '20px 20px 10px 20px'
}

const soruSecenekStyle = {
    fontFamily: 'Avenir',
    fontSize: '18px',
    color: '#808080',
    padding: '15px 20px 5px 20px'
}

class SoruAvatar extends Component {
    state = {
        zamanBaslat: '',
        indexSoruSave: this.props.indexSoru,
        updated: false,
        showHint: false
    }

    componentDidMount() {
        let zamanBaslat = new Date();
        this.setState({ zamanBaslat });
    }

    componentDidUpdate() {
        if (this.props.indexSoru !== this.state.indexSoruSave) {
            let zamanBitti = new Date();
            let zaman = zamanBitti - this.state.zamanBaslat;

            this.props.dersSoru.sorular[this.state.indexSoruSave].gecenSure =
            this.props.dersSoru.sorular[this.state.indexSoruSave].gecenSure + zaman;

            this.setState({
                indexSoruSave: this.props.indexSoru,
                zamanBaslat: new Date()
            });
        }
    }

    componentWillUnmount() {
        let zamanBitti = new Date();
        let zaman = zamanBitti - this.state.zamanBaslat;

        this.props.dersSoru.sorular[this.state.indexSoruSave].gecenSure =
        this.props.dersSoru.sorular[this.state.indexSoruSave].gecenSure + zaman;
    }

    onClick = (value) => {
        this.props.soruCoz.verilenCevap = value;    
        this.setState({ updated: true });
    }

    toggleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    }

    render() {
        let {
            metin,
            secenek1,
            secenek2,
            secenek3,
            secenek4,
            verilenCevap,
            dogruCevap,
            ipucu
        } = this.props.soruCoz;
        let secenekler = [
            { cevap: 'A', metin: secenek1 },
            { cevap: 'B', metin: secenek2 },
            { cevap: 'C', metin: secenek3 },
            { cevap: 'D', metin: secenek4 },
        ];

        let randomSec = this.props.randomSec;

        let soruSayisi = this.props.dersSoru.soruSayisi;
        if (soruSayisi === 0)
            soruSayisi = 1;

        let secenek = {};
        let kategoriAdi;

        switch(this.props.zorlukId) {
            case 'O': kategoriAdi = 'Öğren'; break;
            case 'U': kategoriAdi = 'Uygula'; break;
            case 'B':
            default : kategoriAdi = 'Başar'; break;
        }

        return(
            <div className='Soru'>
                <div className='SoruHeader'>
                    Soru {this.props.indexSoru+1}/{soruSayisi}
                </div>
                <div className={`SoruZorluk Zorluk${this.props.zorlukId}`}>
                    {kategoriAdi}
                </div>
 
                <div className='SoruCizgi'></div>
                {
                    this.state.showHint ?
                    <div
                    className='sun-editor-editable'
                    style={soruMetinStyle}
                    dangerouslySetInnerHTML={{ __html: ipucu }} />
                    :
                    <div
                    className='sun-editor-editable'
                    style={soruMetinStyle}
                    dangerouslySetInnerHTML={{ __html: metin }} />
                }
                <div className='SoruCizgi'></div>

                <div className="SoruTable">
                {
                    randomSec.map((s,sec) => {
                        secenek = secenekler[s];
                        return (
                            <div key={s} className="SoruTableRow">
                                <div className='SoruTableCell'>
                                <Button
                                    variant={this.props.dogruCevapGoster === "A" && secenek.cevap === dogruCevap ? 'outline-danger' : 'outline-info'}
                                    size='sm'
                                    value={secenek.cevap}
                                    onClick={(e) => this.onClick(e.target.value)}
                                    active={verilenCevap === secenek.cevap}
                                >{String.fromCharCode(65+sec)}</Button>
                                </div>
                                <div
                                    className="SoruTableCell sun-editor-editable"
                                    style={soruSecenekStyle}
                                    onClick={() => this.onClick(String.fromCharCode(65+s))}
                                    dangerouslySetInnerHTML={{ __html: secenek.metin }} />
                            </div>
                        );
                    })
                }
                </div>
                <div className='SoruCizgi'></div>
                {
                    ipucu !== undefined && ipucu !== null && ipucu !== '' ?
                    <div className='SoruIpucu' onClick={this.toggleHint}>
                        İpucu {this.state.showHint ? 'Gizle' : 'Göster'}
                    </div> : null
                }
            </div>                           
        )
    }
}

export default connect(
    ({ dersSoru, account }) => ({ dersSoru, account }),
    null
)(SoruAvatar);