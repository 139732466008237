import React, { Component } from 'react';
import spinnerImg from './assets/derzane-arma-mavi-200.png';

class Spinner extends Component {
    render() {
        return (
            <div className={`Warning ${this.props.place}`}>
                <img src={spinnerImg} width='100%' alt='derZane.com'/> {this.props.text}
            </div>
        )
    }
}

export default Spinner;