import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import 'suneditor/dist/css/suneditor.min.css';
import 'katex/dist/katex.min.css';

const soruMetinStyle = {
    fontFamily: 'Avenir',
    fontSize: '20px',
    color: '#106c84',
    padding: '20px 20px 10px 20px'
}

const soruSecenekStyle = {
    fontFamily: 'Avenir',
    fontSize: '18px',
    color: '#808080',
    padding: '15px 20px 5px 20px'
}

class SoruGoster extends Component {
    state = {
        showHint: false
    }

    geriDon = () => {
        if (this.props.geriDon)
            this.props.geriDon();
    }

    toggleHint = () => {
        this.setState({ showHint: !this.state.showHint });
    }

    render() {
        const soru = this.props.soru;
        //const secenekler = [];
        let secenekler = [
            { cevap: 'A', metin: soru.secenek1 },
            { cevap: 'B', metin: soru.secenek2 },
            { cevap: 'C', metin: soru.secenek3 },
            { cevap: 'D', metin: soru.secenek4 },
        ];

        let randomSec = this.props.randomSec;
        let secenek = {};
/*
        secenekler.push(soru.secenek1);
        secenekler.push(soru.secenek2);
        secenekler.push(soru.secenek3);
        secenekler.push(soru.secenek4);
*/
        /*
        let kategoriAdi;

        switch(soru.kategoriId) {
            case 'U': kategoriAdi = 'Uygulama'; break;
            case 'A': kategoriAdi = 'Analiz'; break;
            case 'K':
            default : kategoriAdi = 'Kavrama'; break;
        }
        */

        return(
            <div className='Soru'>
                <div className='SoruHeader'>
                    Soru {this.props.soruNo} - Kayıt {soru.soruId}
                </div>
                
                <div className='SoruCizgi'></div>
                {
                    this.state.showHint ?
                    <div
                    className='sun-editor-editable'
                    style={soruMetinStyle}
                    dangerouslySetInnerHTML={{ __html: soru.ipucu }} />
                    :
                    <div
                    className='sun-editor-editable'
                    style={soruMetinStyle}
                    dangerouslySetInnerHTML={{ __html: soru.metin }} />
                }
                <div className='SoruCizgi'></div>

                {/*
                <div className="SoruTable">
                {
                    secenekler.map((secenek,sec) => {
                        return (
                            <div key={sec} className="SoruTableRow">
                                <div className='SoruTableCell'>
                                <Button
                                    variant='outline-info'
                                    size='sm'
                                    value={String.fromCharCode(65+sec)}
                                    active={soru.verilenCevap === String.fromCharCode(65+sec)}
                                >{String.fromCharCode(65+sec)}</Button>
                                </div>
                                <div className="SoruTableCell sun-editor-editable"
                                    style={soruSecenekStyle}
                                    dangerouslySetInnerHTML={{ __html: secenek }} />
                            </div>
                        );
                    })
                }
                </div> */}

                <div className="SoruTable">
                {
                    randomSec.map((s,sec) => {
                        secenek = secenekler[s];
                        return (
                            <div key={s} className="SoruTableRow">
                                <div className='SoruTableCell'>
                                <Button
                                    variant={secenek.cevap === soru.dogruCevap ? 'outline-danger' : 'outline-info'}
                                    size='sm'
                                    value={secenek.cevap}
                                    active={soru.verilenCevap === secenek.cevap}
                                >{String.fromCharCode(65+sec)}</Button>
                                </div>
                                <div
                                    className="SoruTableCell sun-editor-editable"
                                    style={soruSecenekStyle}
                                    dangerouslySetInnerHTML={{ __html: secenek.metin }} />
                            </div>
                        );
                    })
                }
                </div>



                <div className='SoruCizgi'></div>
                {
                    soru.ipucu !== undefined && soru.ipucu !== null &&
                    soru.ipucu !== '' ?
                    <div className='SoruIpucu' onClick={this.toggleHint}>
                        İpucu {this.state.showHint ? 'Gizle' : 'Göster'}
                    </div> : null
                }
                <div className='PageTextCenter'>
                    <Button variant="secondary" onClick={this.geriDon}>Geri Dön</Button>
                </div>
            </div>                           
        )
    }
}

export default connect(
    ({ dersSoru, account }) => ({ dersSoru, account }),
    null
)(SoruGoster);