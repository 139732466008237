import React, { Component } from 'react';
import { connect } from 'react-redux';
import { zamanDersSoru } from './actions/dersSoru';

class Countdown extends Component {
    state = {
        countdown: this.props.setCountdown * 60,
        firstCountdown: this.props.setCountdown * 60,
        currentTime: new Date(),
        startTime: new Date(),
        startCounter: false
    }
  
    componentDidMount() {
      this.timerID = setInterval(() => this.tick(), 1000);

      this.state.currentTime.setMinutes(this.props.setCountdown);
      this.state.currentTime.setSeconds(0);
      this.setState({ startCounter: true });
    }
  
    componentWillUnmount() {
      clearInterval(this.timerID);

      this.props.zamanDersSoru(this.state.firstCountdown - this.state.countdown);
    }
  
    tick() {
        let diff = this.state.countdown - 1;
        let pcTime = new Date();
        let calcTime = this.state.firstCountdown - Math.floor((pcTime.getTime() - this.state.startTime.getTime())/1000);

        if (diff !== calcTime) {
            diff = calcTime;
        }

        if (diff >= 0) {
            let a = Math.floor(diff/60);
            let b = diff - a * 60;
            this.state.currentTime.setMinutes(a);
            this.state.currentTime.setSeconds(b);

            this.setState({
                countdown: diff
            });
        } else {
            this.props.timeIsUp();
        }
    }

    addZero(x, n) {
        while (x.toString().length < n) {
          x = "0" + x;
        }
        return x;
    }
    
    render() {
        const cMin = this.addZero(this.state.currentTime.getMinutes(), 2);
        const cSec = this.addZero(this.state.currentTime.getSeconds(), 2);

        return (
            <div className="Counter">
                Kalan Süre: {this.state.startCounter ? `${cMin}:${cSec}` : null}
            </div>
        );
    }
}

export default connect(
    ({ soru }) => ({ soru }),
    { zamanDersSoru }
)(Countdown);