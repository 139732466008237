import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';

import spinnerImg from './assets/derzane-arma-mavi-200.png';

import { fetchKonu } from './actions/konu';
import { fetchAyarlar, updateAyarlar } from './actions/ayarlar';
import fetchStates from './reducers/fetchStates';

class DersKonulari extends Component {
    state = {
        changed: false,
    }

    componentDidMount() {
        let id = this.props.account.id;
        let sinifId = this.props.sinifId;
        let dersId = this.props.dersId;
        this.props.fetchAyarlar(id, sinifId, dersId);
        this.props.fetchKonu(id, sinifId, dersId);
    }

    handleKategoriIdChange = e => {
        this.props.ayarlar.kategoriId = e;
        this.setState({ changed: true });
    }

    toggleDogruCevapGoster = () => {
        this.props.ayarlar.dogruCevapGoster = (this.props.ayarlar.dogruCevapGoster === 'A' ? 'K' : 'A');
        this.setState({ changed: true });
    }

    toggleSoruFiltreSekli = e => {
        this.props.ayarlar.soruFiltreSekli = e.target.value;
        this.setState({ changed: true });
    }

    toggleZamanTut = () => {
        this.props.ayarlar.zamanTut = (this.props.ayarlar.zamanTut === 'E' ? 'H' : 'E');
        this.setState({ changed: true });
    }

    handleChange = event => {
        let tag = event.target.name;
        let key = event.target.value;

        if (tag === 'konuId') {
            this.props.ayarlar.konuId = key;
            this.setState({ changed: true });
        }
    }

    konuSecildi = () => {
        let id = this.props.account.id;
        let sinifId = this.props.sinifId;
        let dersId = this.props.dersId;
        let {
            konuId,
            kategoriId,
            dogruCevapGoster,
            soruFiltreSekli,
            zamanTut
        } = this.props.ayarlar;

        const konular = this.props.konu.konular;
        const konu = konular.find(konu => { return konu.konuId === Number(konuId) });
        let konuAdi = konu.konuAdi;

        this.props.updateAyarlar({
            id,
            sinifId,
            dersId,
            konuId,
            kategoriId,
            dogruCevapGoster,
            soruFiltreSekli,
            zamanTut
        });

        this.props.konuSecildi(
            konuId,
            konuAdi,
            kategoriId);
    }

    showProgressBar() {
        let kategoriId = this.props.ayarlar.kategoriId;
        const ders = this.props.ders.dersler.find(ders => ders.dersId === this.props.dersId);
        let val;

        switch(kategoriId) {
            case 'O':
                val = Math.floor(ders.cozulenSoruSayisiK/ders.soruSayisiK*100);
                if (isNaN(val)) val = 0;
                if (val > 100) val = 100;
                return <ProgressBar variant="success" now={val} label={`${val}%`}/>;
            case 'U':
                val = Math.floor(ders.cozulenSoruSayisiU/ders.soruSayisiU*100);
                if (isNaN(val)) val = 0;
                if (val > 100) val = 100;
                return <ProgressBar variant="warning" now={val} label={`${val}%`}/>;
            case 'B':
            default:
                val = Math.floor(ders.cozulenSoruSayisiA/ders.soruSayisiA*100);
                if (isNaN(val)) val = 0;
                if (val > 100) val = 100;
                return <ProgressBar variant="danger" now={val} label={`${val}%`}/>;
        }
    }

    render() {
        let ayarlar = this.props.ayarlar;
        if (ayarlar.status === fetchStates.fetching ||
            this.props.konu.status === fetchStates.fetching) {
            return (
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Ayarlar ve konular yükleniyor 
                </div>
            )
        }

        let konular = this.props.konu.konular;

        return (
            <div className="Page">
                <div className="PageInput">
                    <div>
                        Ünite Seç
                    </div>
                    <InputGroup>
                        <FormControl
                            as="select"
                            value={ayarlar.konuId}
                            onChange={this.handleChange}
                            placeholder='konuId'
                            name='konuId'
                        >
                            {
                            konular.map((konu,i) => (
                                <option key={i} value={konu.konuId}>
                                    {konu.konuAdi}
                                </option>
                            ))
                            }
                        </FormControl>  
                    </InputGroup>
                </div>
                <div className="PageInput">
                    <div>
                        Zorluk Aşamasını Seç
                    </div>
                    <InputGroup>
                        <ToggleButtonGroup
                            type="radio"
                            name="kategoriId"
                            defaultValue={ayarlar.kategoriId}
                            onChange={this.handleKategoriIdChange}
                        >
                            <ToggleButton
                                variant={'outline-success'}
                                value={'O'}
                            >
                                Öğren
                            </ToggleButton>
                            <ToggleButton
                                variant={'outline-warning'}
                                value={'U'}
                            >
                                Uygula
                            </ToggleButton>
                            <ToggleButton
                                variant={'outline-danger'}
                                value={'B'}
                            >
                                Başar
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </InputGroup>
                </div>

                {this.showProgressBar()}

                <div className="PageInput">
                    <div>
                        Seçenekler
                    </div>
                    <div className='custom-control custom-checkbox'>
                        <input
                            type="checkbox"
                            className="custom-control-input BackGroundBlue"
                            id="dogruCevapGoster"
                            onChange={this.toggleDogruCevapGoster}
                            value='A'
                            checked={ayarlar.dogruCevapGoster === 'A'}
                        />
                        <label className="custom-control-label" htmlFor="dogruCevapGoster">
                            Çözerken doğru cevapları göster
                        </label>
                    </div>
                    <div className='custom-control custom-checkbox'>
                        <input
                            type="checkbox"
                            className="custom-control-input BackGroundBlue"
                            id="zamanTut"
                            onChange={this.toggleZamanTut}
                            value='E'
                            checked={ayarlar.zamanTut === 'E'}
                        />
                        <label className="custom-control-label" htmlFor="zamanTut">
                            Zamanlı test yapmak istiyorum
                        </label>
                    </div>
                </div>
                <div className="PageInput">
                    <div>
                        Test sorularını oluştururken
                    </div>
                    <div className='custom-control custom-radio'>
                        <input
                            type="radio"
                            className="custom-control-input BackGroundBlue"
                            id="soruFiltreSekli1"
                            onChange={this.toggleSoruFiltreSekli}
                            value='T'
                            checked={ayarlar.soruFiltreSekli === 'T'}
                        />
                        <label className="custom-control-label" htmlFor="soruFiltreSekli1">
                            Sadece daha önce çözmediğim sorulardan seç
                        </label>
                    </div>
                    <div className='custom-control custom-radio'>
                        <input
                            type="radio"
                            className="custom-control-input BackGroundBlue"
                            id="soruFiltreSekli2"
                            onChange={this.toggleSoruFiltreSekli}
                            value='Y'
                            checked={ayarlar.soruFiltreSekli === 'Y'}
                        />
                        <label className="custom-control-label" htmlFor="soruFiltreSekli2">
                            Daha önce çözmediğim, çözüp yanlış yaptığım ve boş bıraktığım sorulardan seç
                        </label>
                    </div>
                    <div className='custom-control custom-radio'>
                        <input
                            type="radio"
                            className="custom-control-input BackGroundBlue"
                            id="soruFiltreSekli3"
                            onChange={this.toggleSoruFiltreSekli}
                            value='C'
                            checked={ayarlar.soruFiltreSekli === 'C'}
                        />
                        <label className="custom-control-label" htmlFor="soruFiltreSekli3">
                            Daha önce çözüp çözmediğime bakmaksızın, tüm sorulardan seç
                        </label>
                    </div>
                </div>
                <div className="PageTextCenter">
                    <Button variant='info' onClick={this.konuSecildi}>Teste Başla</Button>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ ayarlar, account, konu, ders }) => ({ ayarlar, account, konu, ders }),
    { fetchKonu, fetchAyarlar, updateAyarlar }
)(DersKonulari);