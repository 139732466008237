import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import { fetchDers } from './actions/ders';
import { Redirect } from 'react-router-dom';
import fetchStates from './reducers/fetchStates';
import spinnerImg from './assets/derzane-arma-mavi-200.png';
import HeaderSub from './HeaderSub';
import CoverItem from './CoverItem';

import dersImg from './assets/ders.jpg';
import matImg from './assets/mat.jpg';
import fenImg from './assets/fen.jpg';
import trkImg from './assets/trk.jpg';
import inkImg from './assets/ink.jpg';

let gRedirect = 'none';

class AnaSayfa extends Component {
    state = {
        redirectUpdated: false
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = "AnaSayfa: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        this.props.fetchDers({ id });
        window.scrollTo(0, 0);
    }

    callPage = (redirect) => {
        gRedirect = redirect;
        this.setState({ redirectUpdated: true });
    }

    profilimGuncelMi() {
        if (this.props.account.sinifId === 'XX') {
            return false;
        }
        if (this.props.account.okulId === 'XX') {
            return false;
        }
        if (this.props.account.cinsiyet === '') {
            return false;
        }
        if (this.props.account.soyad === '') {
            return false;
        }
        if (this.props.account.ad === '') {
            return false;
        }

        return true;
    }

    render() {
        // const gecerliTarih = this.props.account.mat; aşağıdaki kısım açılınca bu da açılacak
        const dersler = this.props.ders.dersler;
        let lRedirect = 'none';

        if (gRedirect === 'none') {
            return (
                <div>
                    <HeaderSub header="Ana Sayfa" />
                    {
                    this.props.ders.status === fetchStates.fetching ?
                    <div className="Warning w_top">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Dersler yükleniyor
                    </div> : 
                    this.props.account.accountStatus === "I0" ?
                    <div className="Warning w_top">
                        Yeni oluşturduğunuz hesabınızı aktifleştirebilmeniz için e-posta adresinizi doğrulamanız gerekmektedir. Lütfen size göndermiş olduğumuz E-Posta doğrulama mesajımızdaki butona tıklayarak hesabınızı doğrulayınız. Daha önce doğruladıysanız, lütfen sisteme tekrar giriş yapınız. Eğer doğrulama mesajımızı almadıysanız 'Profilim' seçeneğinden tekrar doğrulama mesajı gönderebilirsiniz.
                    </div> :
                    this.props.account.sozlesme === 0 ?
                    <div className="Warning w_top">
                        Lütfen <span className="Turuncu cursorPointer" onClick={() => this.callPage("/gizlilik-politikasi")}>gizlilik politikasını</span> okuyup kabul ediniz
                    </div> :
                    !this.profilimGuncelMi() ?
                    <div className="Warning  w_top">
                        Lütfen <span className="Turuncu cursorPointer" onClick={() => this.callPage("/profilim")}>profilinizi</span> güncelleyiniz
                    </div> : null
                    }
                    <div className="Page">
                        <div className="PageText">
                            Lütfen kayıt bilgilerinizi güncel tutunuz.
                            {/*
                            Deneme üyeliğiniz {gecerliTarih.substr(6,2)}.{gecerliTarih.substr(4,2)}.{gecerliTarih.substr(0,4)} tarihine kadar geçerlidir.
                            Daha uzun süreli kullanım için lütfen abone olunuz.
                            */}
                        </div>
                    </div>
                    <div className="Cover">
                        {/*
                        <CoverItem
                            id="Deneme"
                            header="Deneme Sınavı"
                            image={hakkimizdaImg}
                            redirect="/deneme-sinavi"
                            callPage={this.callPage}
                        />
                        */}
                        {
                            dersler.map((ders, index) => {
                                let soruSayisi = 0;
                                if (ders.soruSayisiA > 0)
                                    soruSayisi += +ders.soruSayisiA;
                                if (ders.soruSayisiK > 0)
                                    soruSayisi += +ders.soruSayisiK;
                                if (ders.soruSayisiU > 0)
                                    soruSayisi += +ders.soruSayisiU;

                                return (<CoverItem
                                    key={index}
                                    id={ders.dersId}
                                    header={ders.dersAdi}
                                    soruSayisi={soruSayisi}
                                    image={(ders.dersId === 'Fen' ? fenImg : (ders.dersId === 'Ink' ? inkImg : (ders.dersId === 'Trk' ? trkImg : (ders.dersId === 'Mat' ? matImg : dersImg ) )))}
                                    redirect={`/${ders.dersId.toLowerCase()}`}
                                    callPage={this.callPage}
                                    active={true}
                                />)
                            })
                        }
                    </div>
                    {
                    this.props.ders.status === fetchStates.fetching ?
                    <div className="Warning w_bottom">
                        <img src={spinnerImg} width='100%' alt='derZane.com'/> Dersler yükleniyor
                    </div> :
                    this.props.account.accountStatus === "I0" ?
                    <div className="Warning w_bottom">
                        Yeni oluşturduğunuz hesabınızı aktifleştirebilmeniz için e-posta adresinizi doğrulamanız gerekmektedir. Lütfen size göndermiş olduğumuz E-Posta doğrulama mesajımızdaki butona tıklayarak hesabınızı doğrulayınız. Daha önce doğruladıysanız, lütfen sisteme tekrar giriş yapınız. Eğer doğrulama mesajımızı almadıysanız 'Profilim' seçeneğinden tekrar doğrulama mesajı gönderebilirsiniz.
                    </div> :
                    this.props.account.sozlesme === 0 ?
                    <div className="Warning w_bottom">
                        Lütfen <span className="Turuncu cursorPointer" onClick={() => this.callPage("/gizlilik-politikasi")}>gizlilik politikasını</span> okuyup kabul ediniz
                    </div> :
                    !this.profilimGuncelMi() ?
                    <div className="Warning  w_bottom">
                        Lütfen <span className="Turuncu cursorPointer" onClick={() => this.callPage("/profilim")}>profilinizi</span> güncelleyiniz
                    </div> : null
                    }
                </div>
            );
        }
        lRedirect = gRedirect;
        gRedirect = 'none';
        return (<Redirect push to={lRedirect} />);
    }
}

export default connect(
    ({ account, ders }) => ({ account, ders }),
    { invokeLogger, fetchDers }
)(AnaSayfa);
