import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchStates from './reducers/fetchStates';

class CevapAnahtariSatir extends Component {
    soruGoster = () => {
        if (this.props.soruGoster)
            this.props.soruGoster();
    }

    render() {
        if (this.props.altkonu.status === fetchStates.success) {
            const soru = this.props.soru;
            let randomSec = this.props.randomSec;
            const dogru = randomSec.findIndex(obj => {
                return obj === (soru.dogruCevap.charCodeAt(0) - 65)
            });
            const dogruCevap = String.fromCharCode(dogru + 65);
            let verilenCevap;
            if (soru.verilenCevap === '' || soru.verilenCevap === undefined) {
                verilenCevap = '-';
            } else {
                const verilen = randomSec.findIndex(obj => {
                    return obj === (soru.verilenCevap.charCodeAt(0) - 65)
                });
                verilenCevap = String.fromCharCode(verilen + 65);
            }

            let renkStyle = (
                dogruCevap === verilenCevap ? 'SoruDogruCevapli' :
                verilenCevap === '-' ? 'SoruCevapsiz': 'SoruCevapli'
            );

            const altKonu = this.props.altkonu.altkonular.find(obj => {
                return Number(obj.altKonuId) === soru.altKonuId
            });

            return(
                <div className="CevapAnahtariSatir" onClick={this.soruGoster}>
                    <div className='CevapAnahtariHucre'>{this.props.soruNo}</div>
                    <div className='CevapAnahtariHucre'>{soru.soruId}</div>
                    <div className='CevapAnahtariHucre'>{verilenCevap}</div>
                    <div className='CevapAnahtariHucre'>{dogruCevap}</div>
                    <div className={'CevapAnahtariHucre ' + renkStyle}>
                        {(verilenCevap === '-' ? '-' : dogruCevap === verilenCevap ? '√' : 'X')}
                    </div>
                    <div className='CevapAnahtariHucre'>{this.props.zamanString(soru.gecenSure/1000)}</div>
                    <div className='CevapAnahtariHucre'>{altKonu.altKonuAdi}</div>
                </div>
            )               
        } else
            return (null);
    }
}

export default connect(
    ({ altkonu }) => ({ altkonu }),
    null
)(CevapAnahtariSatir);