export const validateGratisDate = (gratisDate) => {

    let sDate = new Date();
    if (`${sDate.getFullYear()}` > gratisDate.substr(0, 4))
        return false;

    const mMonth = sDate.getMonth() > 8 ? (sDate.getMonth()+1) : "0" + (sDate.getMonth()+1);
    if (mMonth > gratisDate.substr(4, 6))
        return false;

    const mDay = sDate.getDate() > 9 ? sDate.getDate() : "0" +  sDate.getDate();
    if (mDay > gratisDate.substr(6, 8))
        return false;

    return true;
}

export const dateString = () => {
    const date = new Date();
    const mMonth = date.getMonth() > 8 ? (date.getMonth()+1) : "0" + (date.getMonth()+1);
    const mDay = date.getDate() > 9 ? date.getDate() : "0" +  date.getDate();
    const mHour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours();
    const mMin = date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();
    const mSec = date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
    const mMilli = date.getMilliseconds() > 99 ? date.getMilliseconds() : 
                   date.getMilliseconds() > 9 ? "0" + date.getMilliseconds() : "00" + date.getMilliseconds();

    return (`${date.getFullYear()}${mMonth}${mDay}_${mHour}${mMin}${mSec}.${mMilli}`);
}

export const getKategoriAdi = (kategoriId) => {
    switch (kategoriId.toLowerCase()) {
        case 'd': return 'deneme';
        case 'o': return 'ogren';
        case 'u': return 'uygula';
        case 'b': return 'basar';
        default : return 'deneme';
    }
}