import { BACKEND } from '../secrets/config';
import { AYARLAR } from './types';

export const fetchAyarlar = (id, sinifId, dersId) => dispatch => {
    dispatch({ type: AYARLAR.FETCH });

    return fetch(`${BACKEND.ADDRESS}/Ayarlar/Oku`, {
            method: 'POST',
            body: JSON.stringify({ id, sinifId, dersId }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: AYARLAR.FETCH_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: AYARLAR.FETCH_SUCCESS,
                    ayarlar: json
                });
            }
        })
        .catch(error => dispatch({
            type: AYARLAR.FETCH_ERROR,
            message: error.message
        }));
};

export const updateAyarlar = (ayar) => dispatch => {

    dispatch({ type: AYARLAR.UPDATE });

    return fetch(`${BACKEND.ADDRESS}/Ayarlar/Yaz`,
        {
                method: 'POST',
                body: JSON.stringify(ayar),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: AYARLAR.UPDATE_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: AYARLAR.UPDATE_SUCCESS,
                    ayarlar: json
                });
            }
        })
        .catch(error => dispatch({
            type: AYARLAR.UPDATE_ERROR,
            message: error.message
            }));
};