import React, { Component } from 'react';
import { connect } from 'react-redux';
import { zamanDersSoru } from './actions/dersSoru';

class Counter extends Component {
    state = {
        counter: 0,
        currentTime: new Date(),
        startCounter: false
    }
  
    componentDidMount() {
      this.timerID = setInterval(() => this.tick(), 1000);

      this.state.currentTime.setMinutes(0);
      this.state.currentTime.setSeconds(0);
      this.setState({ startCounter: true });
    }
  
    componentWillUnmount() {
      clearInterval(this.timerID);

      this.props.zamanDersSoru(this.state.counter);
    }
  
    tick() {
        let ddd = this.state.counter + 1;

        let a = Math.floor(ddd/60);
        let b = ddd - a * 60;
        this.state.currentTime.setMinutes(a);
        this.state.currentTime.setSeconds(b);

        this.setState({
            counter:  ddd
        });      
    }

    addZero(x, n) {
        while (x.toString().length < n) {
          x = "0" + x;
        }
        return x;
    }
    
    render() {
        const cMin = this.addZero(this.state.currentTime.getMinutes(), 2);
        const cSec = this.addZero(this.state.currentTime.getSeconds(), 2);

        return (
        <div>
            { this.props.goster ?
                <div className="Counter">
                    Geçen Süre: {this.state.startCounter ? `${cMin}:${cSec}` : null}
                </div>
                :
                <div></div>
            }
        </div>
        )
    }
}

export default connect(
    ({ dersSoru }) => ({ dersSoru }),
    { zamanDersSoru }
)(Counter);