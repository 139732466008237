import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';
import DersKonulari from './DersKonulari';
import TestYap from './TestYap';
import CevapAnahtari from './CevapAnahtari';
import { fetchAltKonu } from './actions/altkonu';
import fetchStates from './reducers/fetchStates';

class Test extends Component {
    state = {
        dersSectir: true,
        testAcik: false,
        cevapAnahtari: false,
        konuId: '',
        konuAdi: '',
        kategoriId: '',
        randomSec: this.setRandomSelection()
    }

    componentDidMount() {
        const id = this.props.account.id;
        const text = this.props.dersId + " Testi: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    setRandomSelection() {
        let rSayi = 0;
        const rSec = [];

        while ( rSec.length < 4) {
            rSayi = Math.floor(Math.random()*40000/10000);
            if (rSec.indexOf(rSayi) === -1)
                rSec.push(rSayi);
        }
        return rSec;
    }

    konuSecildi = (konuId, konuAdi, kategoriId) => {
        this.setState({
            testAcik: true,
            dersSectir: false,
            konuId,
            konuAdi,
            kategoriId
        });

        let id = this.props.account.id;
        let sinifId = '8';
        let dersId = this.props.dersId;
        this.props.fetchAltKonu(id, sinifId, dersId, konuId);
    }

    cevapAnahtariKapat = () => {
        this.setState({ cevapAnahtari: false, dersSectir: true });
    }
/*
    birTestDaha = () => {
        this.setState({ cevapAnahtari: false, testAcik: true });
    }
*/
    timeIsUp = (tag) => {
        if (tag === 'DersKonulari') {
            this.setState({ testAcik: false, dersSectir: true });
        } else {
            this.setState({ testAcik: false, cevapAnahtari: true });
        }
    }

    render() {
        /* if (this.props.account.accountStatus !== 'FM' &&
            this.props.account.accountStatus !== 'AD') {
            return (
                <div>
                    <HeaderSub header='Abonelere Özel Bölüm' />
                    <div className="Warning w_top">
                    Bu alan sadece abone olan üyelerimiz içindir. Abone olmak için sitemizden detaylı bilgi edinebilirsiniz.
                    </div>
                </div>
            );
        } */

        if (this.props.ders.status === fetchStates.fetching) {
            console.log('fetching');
        }

        const dersler = this.props.ders.dersler;
        const ders = dersler.find(ders => { return ders.dersId === this.props.dersId });

        return (
            <div>
                <HeaderSub header={ders.dersKisaAdi +
                    (this.state.dersSectir ? ' - Üniteler' :
                    this.state.testAcik ? ' Testi' :
                    this.state.cevapAnahtari ? ' - Cevap Anahtarı' : '')} />
                {
                    this.state.dersSectir ? 
                    <DersKonulari
                        sinifId='8'
                        dersId={ders.dersId}
                        dersAdi={ders.dersAdi}
                        konuSecildi={this.konuSecildi}
                    /> :
                    this.state.testAcik ? 
                    <TestYap
                        sinifId='8'
                        dersId={ders.dersId}
                        dersAdi={ders.dersAdi}
                        konuId={this.state.konuId}
                        konuAdi={this.state.konuAdi}
                        kategoriId={this.state.kategoriId}
                        timeIsUp={this.timeIsUp}
                        randomSec={this.state.randomSec}
                    /> :
                    this.state.cevapAnahtari ?
                    <CevapAnahtari
                        sinifId='8'
                        dersId={ders.dersId}
                        dersAdi={ders.dersAdi}
                        konuId={this.state.konuId}
                        konuAdi={this.state.konuAdi}
                        kategoriId={this.state.kategoriId}
                        kapat={this.cevapAnahtariKapat}
                        //birTestDaha={this.birTestDaha}
                        randomSec={this.state.randomSec}
                    /> : null
                }
            </div>
        )
    }
}

export default connect(
    ({ account, ders }) => ({ account, ders }),
    { invokeLogger, fetchAltKonu }
)(Test);