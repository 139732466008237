import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Iletisim extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "Iletisim: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='İletişim'/>
                <div className="Page">
                    <div className="PageText">
                        Bize ulaşmak isterseniz iletisim@derzane.com adresine e-posta atabilirsiniz.
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Iletisim);