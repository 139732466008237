import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './Menu';
import Footer from './Footer';
import Tail from './Tail';

import AuthForm from './AuthForm';
import Profilim from './Profilim';
import AboneOl from './AboneOl';
import AnaSayfa from './AnaSayfa';
import Test from './Test';
import Stats from './Stats';
import SSS from './SSS';
import ContactFormFiled from './ContactFormFiled';
import Iletisim from './Iletisim';
import GizlilikPolitikasi from './GizlilikPolitikasi';
import DenemeAsamasi from './DenemeAsamasi';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Menu />
        {
          !this.props.account.loggedIn ? <AuthForm /> :
          <Switch>

                <Route path='/deneme-asamasi' component={DenemeAsamasi} />
                <Route path='/profilim' component={Profilim} />
                <Route path='/abonelik' component={AboneOl} />
                <Route path='/destek' component={Iletisim} />
                <Route path='/sik-sorulan-sorular' component={SSS} />
                <Route path='/iletisim-formu-alindi' component={ContactFormFiled} />
                <Route path='/iletisim' component={Iletisim} />
                <Route path='/gizlilik-politikasi' component={GizlilikPolitikasi} />

                {/*<Route path='/deneme-sinavi' component={Iletisim} />*/}
                <Route path='/mat'><Test dersId='Mat'/></Route>
                <Route path='/fen'><Test dersId='Fen'/></Route>
                <Route path='/trk'><Test dersId='Trk'/></Route>
                <Route path='/ink'><Test dersId='Ink'/></Route>
                <Route path='/din'><Test dersId='Din'/></Route>
                <Route path='/yen'><Test dersId='Yen'/></Route>
                <Route path='/istatistiklerim' component={Stats} />
                <Route path='/' component={AnaSayfa}/>
          </Switch>
        }
        <Tail />
        <Footer />
      </BrowserRouter>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  null
)(App);