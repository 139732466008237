import { BACKEND } from '../secrets/config';
import { STATS } from './types';

export const getStats = (id) => dispatch => {
    dispatch({ type: STATS.FETCH });

    let options = {
        method: 'POST',
        body: JSON.stringify(id),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    };

    return fetch(`${BACKEND.ADDRESS}/Stats/Getir`, options)
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({ type: STATS.FETCH_ERROR, message: json.message });
            } else {
                dispatch({ type: STATS.FETCH_SUCCESS, dersler: json });
            }
        })
        .catch(error => dispatch({ type: STATS.FETCH_ERROR, message: error.message }));
};