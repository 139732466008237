import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { logout } from './actions/account';

import derZaneLogo from './assets/derzane-logo-2000.png';
import menuImg from './assets/menubar.png';

class Menu extends Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        smallMenuLimit: 768
    }

    getMenuSize = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('resize', this.getMenuSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getMenuSize);
    }

    logout = () => {
        const id = this.props.account.id;
        this.props.logout(id);
    }

    render() {
        return (
            <div className="Menu">
                <div className="MenuLogo">
                    <Link to='/'>
                        <img src={derZaneLogo} width="100%" alt='derZane.com' />
                    </Link>
                </div>     
                {
                this.props.account.loggedIn ?       
                <div className="MenuContainer">
                    {/*
                        this.state.innerWidth >= this.state.smallMenuLimit &&
                        this.props.account.accountStatus === 'FM' ? // burası deneme aşamasından sonra değişecek
                        <div>
                            <Link className="MenuItem" to='/abonelik'>Abone Ol</Link>
                        </div> : <div>&nbsp;</div>
                    */}
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/'>Ana Sayfa</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/profilim'>Profilim</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/istatistiklerim'>İstatistiklerim</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div className="MenuItem" onClick={this.logout}>
                            Çıkış
                        </div> : null
                    }
                </div> : null
                }
                {
                this.props.account.loggedIn ?
                <div className="MenuRightContainer">
                    <div className="MenuShort">
                        <img className="MenuIcon" src={menuImg} alt="derZane.com" />
                        <div className="MenuShortContent">
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/">Ana Sayfa</Link></div>
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/profilim">Profilim</Link></div>
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/istatistiklerim">İstatistiklerim</Link></div>
                            <div className="MenuShortItem" onClick={this.logout}>Çıkış</div>
                        </div>
                    </div>
                </div> : null
                }
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { logout }
)(Menu);