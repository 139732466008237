import React, { Component } from 'react';
import { connect } from 'react-redux';
//import Button from 'react-bootstrap/Button';
import { invokeLogger } from './actions/account';
import { getStats } from './actions/stats';
import fetchStates from './reducers/fetchStates';
import HeaderSub from './HeaderSub';
import Spinner from './Spinner';

/*
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
*/
/*
import html2pdf from 'html2pdf.js';
*/

class Stats extends Component {
    componentDidMount() {
        const id = this.props.account.id;
        const text = "İstatistiklerim: " + this.props.account.email;
        this.props.invokeLogger({ id, text });
        this.props.getStats({ id });
        window.scrollTo(0, 0);
    }

    /*
    createPdf = () => {
        const docToPrint = document.querySelector("#docToPrint");

        var opt = {
        margin:       0,
        filename:     'download.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 1 },
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
        }

        html2pdf().from(docToPrint).save();
    }
    */

    /*
    createPdf = () =>
    {
        const docToPrint = document.querySelector("#docToPrint");

        let canvasOptions = {
            scale: 1
        };

        html2canvas(docToPrint, canvasOptions).then(canvas => {
            const imgData = canvas.toDataURL('image/jpg', 1.0);

            const htmlWidth = docToPrint.clientWidth;
            const htmlHeight = docToPrint.clientHeight;
            const a4Width = 595;
            const a4Height = 842;
            const pdfWidth = a4Width;
            const pdfHeight = htmlHeight * pdfWidth / htmlWidth;
            
            const pdf = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4'
            });

            console.log(a4Width, a4Height);
            console.log(htmlWidth, htmlHeight);
            if (pdfHeight <= a4Height) {
                pdf.addImage(imgData, 'JPG', 0, 0, pdfWidth, pdfHeight);
            } else {
                let pages = Math.ceil(pdfHeight / a4Height);
                console.log(pages);
                pdf.addImage(imgData, 'JPEG', 0, 0, a4Width, a4Height);
                for (let i=0; i < pages; i++) {
                    pdf.addPage();
                    pdf.addImage(imgData, 'JPEG', 0, a4Height*i, a4Width, a4Height);
                }
            }
            
            pdf.save('download.pdf');
        });
    } */

    listStats(ders) {
        let lines = '';
        const liste = ders.liste;
        let kod = '';
        let oncekiKod = '';
        let satir;
        let oncekiKategori = '';
        let kategoriAdi;
        let satirToplam = 0;
        let satirOrtalamaSure = 0;
        let netPuan = 0;
        let yuzdeDogru = 0;
        let cevaplar = [];

        if (liste.length > 0) {
            oncekiKategori = '';
            lines += `<div class="PageTableHeader">${ders.dersAdi}</div>
                        <div class="CevapAnahtari">
                        <div class="CevapAnahtariBaslik">
                        <div class="CevapAnahtariHucre">Ünite</div>
                        <div class="CevapAnahtariHucre">Konu</div>
                        <div class="CevapAnahtariHucre">Zorluk</div>
                        <div class="CevapAnahtariHucre">Boş</div>
                        <div class="CevapAnahtariHucre">Doğru</div>
                        <div class="CevapAnahtariHucre">Yanlış</div>
                        <div class="CevapAnahtariHucre">% Doğru</div>
                        <div class="CevapAnahtariHucre">Net</div>
                        <div class="CevapAnahtariHucre">Ortalama Süre (sn)</div>
                        </div>
            `;
            for (let i=0; i < liste.length; i++) {
                satir = liste[i];
                kod = `${ders.dersId}.8.${satir.konuId}.${satir.altKonuId}`;
                if (oncekiKod !== kod) {
                    if (oncekiKod !== '') {
                        while (cevaplar.length < 3) {
                            cevaplar.push(0);
                            lines += '<div class="CevapAnahtariHucre">&nbsp;</div>';
                        }
                        yuzdeDogru = Math.round(cevaplar[1] / (cevaplar[0] + cevaplar[2]) * 10000)/100;
                        netPuan = Math.round((cevaplar[1]-cevaplar[2]/3)*100)/100;
                        if (netPuan < 0) netPuan = 0;
                        lines += `
                                <div class="CevapAnahtariHucre">${yuzdeDogru}</div>
                                <div class="CevapAnahtariHucre">${netPuan}</div>
                                <div class="CevapAnahtariHucre">${Math.round(Number(satirOrtalamaSure/satirToplam)/1000)}</div>
                                </div>
                        `;
                    }
                    lines += `
                        <div class="CevapAnahtariSatir">
                        <div class="CevapAnahtariHucre">${satir.konuAdi}</div>
                        <div class="CevapAnahtariHucre">${satir.altKonuAdi}</div>`;
                    oncekiKod = kod;
                    oncekiKategori = '';
                }
                if (oncekiKategori !== satir.kategoriId) {
                    if (oncekiKategori !== '') {
                        while (cevaplar.length < 3) {
                            cevaplar.push(0);
                            lines += '<div class="CevapAnahtariHucre">&nbsp;</div>';
                        }
                        yuzdeDogru = Math.round(cevaplar[1] / (cevaplar[0] + cevaplar[2]) * 10000)/100;
                        netPuan = Math.round((cevaplar[1]-cevaplar[2]/3)*100)/100;
                        if (netPuan < 0) netPuan = 0;
                        lines += `
                                <div class="CevapAnahtariHucre">${yuzdeDogru}</div>
                                <div class="CevapAnahtariHucre">${netPuan}</div>
                                <div class="CevapAnahtariHucre">${Math.round(Number(satirOrtalamaSure/satirToplam)/1000)}</div>
                                </div>
                                <div class="CevapAnahtariSatir">
                                <div class="CevapAnahtariHucre"></div>
                                <div class="CevapAnahtariHucre"></div>
                                `;
                    }   
                    switch(satir.kategoriId) {
                        case 'O': kategoriAdi = 'Öğren'; break;
                        case 'U': kategoriAdi = 'Uygula'; break;
                        default :
                        case 'B': kategoriAdi = 'Başar'; break;
                    }
                    lines += `
                            <div class="CevapAnahtariHucre">${kategoriAdi}</div>`;
                    oncekiKategori = satir.kategoriId;
                    satirToplam = 0;
                    satirOrtalamaSure = 0;
                    cevaplar = [];
                }
                cevaplar.push(satir.toplam);
                satirToplam += satir.toplam;
                satirOrtalamaSure += satir.toplam * satir.ortalamaSure;
                lines += `<div class="CevapAnahtariHucre">${satir.toplam}</div>`;
            }
            while (cevaplar.length < 3) {
                cevaplar.push(0);
                lines += '<div class="CevapAnahtariHucre">&nbsp;</div>';
            }
            yuzdeDogru = Math.round(cevaplar[1] / (cevaplar[0] + cevaplar[2]) * 10000)/100;
            netPuan = Math.round((cevaplar[1]-cevaplar[2]/3)*100)/100;
            if (netPuan < 0) netPuan = 0;
            lines += `
                    <div class="CevapAnahtariHucre">${yuzdeDogru}</div>
                    <div class="CevapAnahtariHucre">${netPuan}</div>
                    <div class="CevapAnahtariHucre">${Math.round(Number(satirOrtalamaSure/satirToplam)/1000)}</div>
                    </div>
                    `;
            lines += "</div>";
        }

        return lines;
    }

    render() {
        const stats = this.props.stats.dersler;
/*
        if (this.props.account.accountStatus !== 'FM' &&
            this.props.account.accountStatus !== 'AD' ) {
            return (
                <div>
                    <HeaderSub header='Abonelere Özel Bölüm' />
                    <div className="Warning w_top">
                    Bu alan sadece abone olan üyelerimiz içindir. Abone olmak için sitemizden detaylı bilgi edinebilirsiniz.
                    </div>
                </div>
            );
        }
*/        
        return (  
            <div>
                <HeaderSub header='İstatistiklerim'/>
                {
                    this.props.stats.status === fetchStates.fetching ?
                    <Spinner place='w_top' text='İstatistikleri yüklüyorum' /> : null
                }
                <div id='docToPrint'>
                    {
                        this.props.stats.status === fetchStates.success ?
                        <div className="Page">      
                        {
                            stats.map((ders, index) => {
                                return (
                                    <div key={index}
                                        dangerouslySetInnerHTML={{ __html: this.listStats(ders) }} />
                                )    
                            })
                        }
                        </div> : null
                    }
                </div>
                {/*
                <div className='PageTextCenter'>
                    <Button variant='info' onClick={this.createPdf}>
                        Pdf Oluştur
                    </Button>
                </div>
                */}
            </div>
        )
    }
}

export default connect(
    ({ account, stats, ders }) => ({ account, stats, ders }),
    { invokeLogger, getStats }
)(Stats);