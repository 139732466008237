import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import fetchStates from './reducers/fetchStates';
import { fetchDersSoru, cevapInsert, fetchDersSoruFromSoruId } from './actions/dersSoru';

import { getKategoriAdi, dateString } from './helper';
import SoruAvatar from './SoruAvatar';
import SoruBar from './SoruBar'; 
import Counter from './Counter';
import Countdown from './Countdown';

import spinnerImg from './assets/derzane-arma-mavi-200.png';

class TestYap extends Component {
    state = {
        testBasladi: false,
        index: 0
    }

    componentDidMount() {
        this.props.dersSoru.soruSayisi = 0;
        const id = this.props.account.id;
        const sinifId = this.props.sinifId;
        const dersId = this.props.dersId;
        const konuId = this.props.konuId;
        const kategoriId = this.props.kategoriId;
        const soruFiltreSekli = this.props.ayarlar.soruFiltreSekli;
        const ders = this.props.ders.dersler.find(obj => { return obj.dersId === dersId });
        const soruSayisi = ders.soruSayisi[getKategoriAdi(kategoriId)];

        this.props.fetchDersSoru({
            id,
            sinifId,
            dersId,
            konuId,
            kategoriId,
            soruFiltreSekli,
            soruSayisi
        });

        this.setState({ testBasladi: true, index: 0 });
//        this.getSoruDetails(0);
    }

    componentWillUnmount() {
        this.cevaplariKaydet();
    }

    getSoruDetails(index) {
        /*
        let soru = {};
        soru.id = this.props.account.id;
        soru.sinifId = this.props.sinifId;
        soru.dersId = this.props.dersId;
        soru.soruId = this.props.dersSoru.sorular[index].soruId;

        if (this.props.dersSoru.status === fetchStates.fetching)
            console.log('MAM 1', soru);
        else
            console.log('MAM 2', soru);

        this.props.fetchDersSoruFromSoruId(soru);
        */
    }

    cevaplariKaydet() {
        if (this.props.dersSoru.soruSayisi === 0)
            return;
            
        const cevap = {};
        const sorular = this.props.dersSoru.sorular;
        cevap.id = this.props.account.id;
        cevap.tarih = dateString();
        cevap.sinifId = this.props.sinifId;
        cevap.dersId = this.props.dersId;
        cevap.kategoriId = this.props.kategoriId;
        cevap.sonuclar = [];
        let sonuc = [];
        let alinanSonuc = '';

        sorular.map((soru, index) => {
            alinanSonuc = soru.verilenCevap === '' ||
                                soru.verilenCevap === undefined ? 'B' :
                          soru.verilenCevap === soru.dogruCevap ? 'D' : 'Y';

            sonuc = [];
            sonuc.push(cevap.id);
            sonuc.push(soru.soruId);
            sonuc.push(cevap.tarih);
            sonuc.push(cevap.kategoriId);
            sonuc.push(soru.verilenCevap === undefined ? '' : soru.verilenCevap);
            sonuc.push(alinanSonuc);
            sonuc.push(soru.gecenSure);

            cevap.sonuclar.push(sonuc);
            return null;
        })
        
        this.props.cevapInsert(cevap);
    }

    goIndex = (index) => {
        if (this.state.index !== index ) {
            this.setState({ index });
//            this.getSoruDetails(index);
        }
    }

    timeIsUp = (tag) => this.props.timeIsUp(tag);

    render() {
        const sorular = this.props.dersSoru.sorular;
        let indexSoru = this.state.index;

        const dersId = this.props.dersId;
        const dersAdi = this.props.dersAdi;

        const ders = this.props.ders.dersler.find(obj => { return obj.dersId === dersId });

        let kategoriId = this.props.kategoriId;
        let kategoriAdi = getKategoriAdi(kategoriId);
        let sinavSuresi = ders.verilecekSure[kategoriAdi];
      
        return (
            <div>
            {
                this.props.dersSoru.status === fetchStates.fetching ?
                <div className="Warning w_top">
                    <img src={spinnerImg} width='100%' alt='derZane.com'/> Test soruları yükleniyor 
                </div>
                :
                this.props.dersSoru.soruSayisi === 0 ?
                <div>
                    <div className="Warning w_top">
                        {this.props.dersAdi} dersi {this.props.konuAdi} ile ilgili sorular bulunamadı
                    </div>
                    <div className="PageTextCenter">
                        <Button variant='info' onClick={() => this.timeIsUp('DersKonulari')}>
                            Geri Dön
                        </Button>
                    </div>
                    <div className="Warning w_bottom">
                        {this.props.dersAdi} dersi {this.props.konuAdi} ile ilgili sorular bulunamadı
                    </div>
                </div>
                :
                <div className="Page">           
                    <div className="PageTimer">
                    {
                        this.props.ayarlar.zamanTut === "E" ?
                            <Countdown setCountdown={sinavSuresi} timeIsUp={this.timeIsUp}/> : 
                            <Counter goster={false}/>
                    }
                    </div>
                    <div className="PageText">
                        <SoruBar
                            soruIndex={indexSoru}
                            goIndex={this.goIndex}
                            dogruCevapGoster={this.props.ayarlar.dogruCevapGoster}
                        />
                    </div>
                    <SoruAvatar
                        soruCoz={sorular[indexSoru]}
                        indexSoru={indexSoru}
                        dogruCevapGoster={this.props.ayarlar.dogruCevapGoster}
                        dersId={dersId}
                        dersAdi={dersAdi}
                        sinifId={this.props.sinifId}
                        zorlukId={this.props.kategoriId}
                        randomSec={this.props.randomSec}
                    />
                    <div className="PageTextCenter">
                        <Button variant="info" onClick={this.timeIsUp}>
                            Testi Bitir
                        </Button>
                    </div>
                </div>
            }
            </div>
        )
    }
}

export default connect(
    ({ dersSoru, ayarlar, ders, account }) => ({ dersSoru, ayarlar, ders, account }),
    { fetchDersSoru, cevapInsert, fetchDersSoruFromSoruId }
)(TestYap);